// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 3;
@import url("https://fonts.googleapis.com/css?family=Cinzel|Roboto+Slab:700|Roboto:100,400,400i,700,700i");
@import url('https://use.typekit.net/zme6aqo.css');

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

$theme-colors: (
  primary: #003f87,
  primaryAlt: #003f87,
  accent: #ce1126,
  secondary: #036,
  lBlue: #1b4185,
  info: #067eeb,
  lightContrast: #fdfdfd,
  greyContrast: #e9ecef,
  grey: #f2f2f2,
  darkGrey: #4a4a4a,
  darkerGrey: #232528,
  darkestGrey: #333,
  bodyGrey: #515354,
  gray2: #e6e6e6,
  gray3: #ccc,
  pLight: rgba(0, 63, 135, 0.1)
);

$fam: "Roboto", Helvetica, Arial, sans-serif !default;
$fam2: "trajan-pro-3", serif;
$fam3: "Roboto Slab", serif;

$font: (
  family: $fam,
  familyBrand: $fam2,
  familyTitle: $fam3,
  weight: (
    regular: 400,
    bold: 700,
    light: 100
  )
);

$body-color: map-get($map: $theme-colors, $key: bodyGrey);
$font-family-sans-serif: $fam;
// $font-size-base: 1.375rem;
$font-weight-normal: 400;
$font-weight-bold: 700;
$headings-font-weight: 700;
$headings-color: map-get($map: $theme-colors, $key: primaryAlt);

$text-color: map-get(
  $map: $theme-colors,
  $key: darkGrey
);

.nesa {
  .btn {
    font-weight: map-deep-get($font, weight, bold);
  }

  .btn-link {
    padding: 0;
  }

  .elementor-button {
    @extend .btn;
    @extend .btn-primary;
  }

  .link-button {
    .elementor-button {
      @extend .btn-link;
      border: none;
      color: map-get($map: $theme-colors, $key: info);
      text-decoration: none;
      font-weight: map-deep-get($font, weight, bold);

      &:hover {
        text-decoration: none;
      }
    }
  }

  .bordered-button {
    .elementor-button {
      @extend .btn-outline-primary;
    }
  }
}

.nesa {
  footer.content-info {
    margin-top: 30px;

    .elementor-icon.elementor-social-icon {
      background: linear-gradient(to bottom, map-get($map: $theme-colors, $key: lBlue) 0%,map-get($map: $theme-colors, $key: lBlue) 50%,map-get($map: $theme-colors, $key: secondary) 51%,map-get($map: $theme-colors, $key: secondary) 100%);
      border: 1px solid #fff;
    }

    .post-footer {
      background: map-get($map: $theme-colors, $key: secondary);
      color: #fff;
      padding: 20px 0;
      min-height: 70px;
    }

    p {
      margin: 0;
      margin-top: 10px;
      font-size: 0.9rem;
    }

    .menu {
      text-align: center;
      background-color: transparent !important;

      li {
        display: inline-block;

        &:first-child {
          a {
            background: transparent !important;
            pointer-events: all !important;
            font-weight: $font-weight-normal !important;
          }
        }

        a {
          display: inline-block;
          color: #fff !important;
          border: none !important;
          padding: 10px 12px !important;

          &:hover {
            background-color: transparent !important;
            color: darken($color: #fff, $amount: 10);
          }
        }
      }
    }
  }
}

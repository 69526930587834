.nesa {
  aside.sidebar {
    margin-top: 30px;
  }

  // If you edit this change check if the next items need to be changed too
  .no-heading {
    .widget_nav_menu,
    .elementor-widget-container {
      > ul.menu:not(.sub-menu),
      > div > ul.menu:not(.sub-menu) > .menu-item:first-child {
        a {
          background: transparent;
          color: map-get($map: $theme-colors, $key: darkestGrey);
          font-weight: $font-weight-normal;
          pointer-events: initial;

          &:hover {
            background-color: darken($color: map-get($map: $theme-colors, $key: lightContrast), $amount: 5);
            text-decoration: none;
          }
        }
      }
    }
  }

  .widget_nav_menu,
  .elementor-widget-container {
    list-style: none;

    ul.menu {
      > li.active:not(.current-page-ancestor) > a, .current_page_ancestor > a {
          background-color: map-get($map: $theme-colors, $key: primary);
          color: #fff;

          &:hover {
            background-color: darken($color: map-get($map: $theme-colors, $key: primary), $amount: 10);
            color: #fff;
          }
      }

      // general link stuff
      li > a {
        padding: 10px 20px;
        color: map-get($theme-colors, bodyGrey);
        border-radius: 4px;
        transition: background-color 100ms ease-in;
        display: block;

        &:hover {
          // prettier-ignore
          background-color: darken($color: map-get($map: $theme-colors, $key: lightContrast), $amount: 5);
          text-decoration: none;
        }
      }
    }

    ul.sub-menu {
      background: map-get($map: $theme-colors, $key: grey);

      > li {
        > a:hover {
          background: darken($color: map-get($map: $theme-colors, $key: grey), $amount: 10);
        }

        &.active > a {
          background-color: map-get($map: $theme-colors, $key: gray3);
          color: map-get($map: $theme-colors, $key: darkerGrey);
        }
      }
    }

    > ul.menu:not(.sub-menu),
    > div > ul.menu:not(.sub-menu) {
      padding: 0;
      margin: 0;

      a {
        border-bottom: 1px solid rgba(151, 151, 151, 0.3);
      }

      > li {
        list-style: none;

        ul {
          margin: 0;
          padding: 0;

          > li {
            list-style: none;

            a {
              padding-left: 40px;
              color: map-get($map: $theme-colors, $key: darkestGrey);
            }
          }
        }
      }
    }
  }

  .widget_bsa_pages_navigation,
  .elementor-widget-wp-widget-bsa_pages_navigation {
    position: relative;
    // Pages navigation button on mobile
    .mobile-pages-navigation-toggler {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;
      @include media-breakpoint-up(sm) {
        width: 80%;
        margin: 0 auto;
      }
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
    // Links indented list
    .menu {
      display: none;
      box-shadow: 0px 2px 3px transparentize(#000000, 0.5);
      @include media-breakpoint-up(sm) {
        width: 80%;
        margin: 0 auto;
      }
      @include media-breakpoint-up(md) {
        box-shadow: none;
        display: block !important;
        width: 100%;
        margin: 0;
      }
      // Remove list style from all uls
      ul {
        list-style: none;

        &:not(.children) {
          > li {
            > a {
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 1.25px !important;
              text-transform: uppercase;
            }
          }
        }

        // Give al <li> a white BG by default
        li {
          border-top: solid 1px map-get($map: $theme-colors, $key: gray2);
          background-color: #FFF;

          // Make all <a> be full width for easy pressing
          a {
            color: map-get($map: $theme-colors, $key: bodyGrey);
            display: block;
            width: 100%;
            border-radius: 4px;


            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 15px;
            padding-right: 15px;

            transition: ease 0.3s;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
      // Remove padding from first ul on the list
      > ul {
        padding-left: 0px;
      }

      > ul > li {
        border-top: none;
          
        // Level 2 padding
        > .children > li > a {
          padding-left: 22px;
        }
        // Level 3 padding
        > .children .children > li > a {
          padding-left: 34px;
        }
        // Level 4 padding
        > .children .children > li > .children > li > a {
          padding-left: 45px;
        }
        // Level 5 and on padding
        > .children .children > li > .children > li > .children li a {
          padding-left: 60px;
        }

        &.current_page_item > a {
          border-left:4px;
          border-left-width: 4px;
          border-left-style: solid;
          border-left-color: #007bee;
          background-color: rgba(6,126,235,.1);
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #003f87; 
        }

        // Second level of selection
        > ul > li {
          &.current_page_item > a, &.current_page_ancestor > a {
            border-left:4px;
            border-left-width: 4px;
            border-left-style: solid;
            border-left-color: #007bee;
            background-color: rgba(6,126,235,.1);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #003f87;
          }

          //Third level selection
          > ul > li {
            &.current_page_item > a {
            background-color: map-get($map: $theme-colors, $key: gray3);
            color: map-get($map: $theme-colors, $key: darkerGrey);
            }

            > a {
              background-color: map-get($map: $theme-colors, $key: gray);
            }
          }
        }
      }
    }
  }
}

.nesa {
  margin-top: 65px;
  @media (max-width: 1200px) {
    margin-top: 105px;
  }

  &.elementor-editor-active {
    margin-top: 0;
  }

  &.admin-bar {
    .banner.fixed-top {
      top: 31px;
    }
  }

  .banner {
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    transform: translateZ(0);
  }

  .navbar {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 3rem;

    &.mobilebar {
      padding: 5px 1rem;
      z-index: -2;
    }

    @include media-breakpoint-down(sm) {
      padding-right: 1rem;
    }
  }

  .navbar-nav {
    .nav-link {
      color: #fff;
      padding-right: 1rem;
      padding-left: 1rem;
    }

    .dropdown-menu {
      margin-top: 9px;

      .dropdown-item {
        color: $body-color;
        position: relative;

        &.active {
          color: #fff;
        }

        &::after:not(:last-child) {
          content: '';
          width: calc(100% - 16px);
          height: 1px;
          background: map-get($map: $theme-colors, $key: greyContrast);
          position: absolute;
          bottom: 0;
          left: 8px;
        }
      }
    }
  }

  .nav-item {
    &.bold {
      .nav-link {
        font-weight: $font-weight-bold;
      }
    }
  }

  .navbar-toggler {
    border: none;
    padding: 0;
  }

  .navbar-brand {
    padding: 0.45rem 2rem;
    padding-left: 90px;
    position: relative;
    font-family: $fam2;
    font-size: 1rem;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
      text-indent: -9999px;
      padding-left: 0;
    }

    &::before {
      content: "";
      width: 80px;
      height: 81px;
      border-bottom: none;
      background-image: url(../images/logo-lg.png);
      background-image: url(../images/logo-lg.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
    }

    // &::after {
    //   content: "";
    //   position: absolute;
    //   left: -243px;
    //   bottom: -29px;
    //   z-index: -1;
    //   width: 400px;
    //   height: 400px;
    //   background: #fff;
    //   transform: rotate(157.5deg) skewX(45deg);
    //   box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    // }

    > span {
      display: block;
      font-size: 0.75rem;
    }
  }

  & > nav.logobar {
    display: block;
  }

  & > nav.menubar {
    min-height: 35px;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    .nav-item {
      > .nav-link {
        padding: 0.3rem 1rem;
        color: map-get($map: $theme-colors, $key: primary);
      }

      &:last-child {
        > .nav-link {
          padding-right: 0;
        }
      }
    }
  }

  & > nav.scoutingbar {
    min-height: 30px;
    padding-top: 0;
    padding-bottom: 0;

    .nav-item {
      > .nav-link {
        padding: 0 1rem;
        color: #fff;
        font-size: 0.875rem;
      }

      &:last-child {
        > .nav-link {
          padding-right: 0;
        }
      }
    }

    .scoutingLink {
      font-size: 0.875rem;
    }
  }
}

  h1,
  h2,
  h3,
  .h1,
  .h2,
  .h3 {
    color: map-get($theme-colors, primary);
    font-family: map-get($font, familyTitle);
    word-break: break-word;

    a {
      color: map-get($theme-colors, primary);
    }
  }

  h3,
  h4,
  h5,
  .h3,
  .h4,
  .h5 {
    &.subtitle,
    .subtitle & {
      font-family: map-get($map: $font, $key: family);
      font-weight: map-deep-get($font, weight, light);
    }
  }

  h1,
  .h1 {
    font-size: 2.55rem;

    &.subtitle {
      color: red;
    }
  }

  h2,
  .h2 {
    font-size: 2.25rem;

    &.subtitle {
      color: red;
    }

    &.home {
      font-size: 2.25rem; 
    }

  }

  h3,
  .h3 {
    font-size: 2rem;

    &.subtitle,
    .subtitle & {
      font-size: 1.5rem;
    }
  }

  h4,
  .h4 {
    font-size: 1.5rem;

    &.subtitle {
      font-size: 1.125rem;
    }
  }

  h5,
  .h5 {
    font-size: 1.125rem;

    &.subtitle {
      font-size: 1rem;
    }
  }

html,
body {
  overflow-x: hidden;
}

.nesa {
  &.error404 .wrap {
    padding: 50px 0;
  }

  .btn.more {
    display: inline;

    &::before {
      content: '';
      width: 100%;
      display: block;
    }
  }

  .wrap {
    @include media-breakpoint-up(lg) {
      padding: 0;
    }

    padding: 0;
  }

  b, strong {
    font-weight: map-deep-get($font, weight, bold);
  }

  .breadcrumbs {
    font-size: 0.75rem;

    a {
      color: map-get($map: $theme-colors, $key: bodyGrey);
      font-weight: map-deep-get($font, weight, regular);
      font-size: 0.75rem;

      &:hover {
        text-decoration: underline;
      }
    }

    > span:last-child {
      color: map-get($map: $theme-colors, $key: info);
      pointer-events: none;
    }
  }

  .quote {
    width: 100%;
    text-align: center;
    font-size: 1.25rem;
    padding: 1rem 0;
    font-style: italic;
    clear: both;
  }

  .cliped {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: map-get($map: $theme-colors, $key: grey);
      clip-path: polygon(0 0, 100% 0, 100% 31%, 0 84%);
      z-index: -1;
    }
  }

  .blured {
    filter: blur(3px);
  }

  a {
    color: map-get($map: $theme-colors, $key: info);

    &:hover {
      text-decoration: none;
    }
  }

  .links ul {
    padding: 0;
    margin: 0;
  }
  .links li {
    list-style: none;
  }
  .links a {
    font-size: 1rem;
    color: map-get($map: $theme-colors, $key: info);
    font-weight: bold;
  }

  .elementor-widget-heading,
  .elementor-widget-heading.elementor-widget-heading {
    .elementor-heading-title {
      color: map-get($theme-colors, primaryAlt);

      a {
        color: map-get($theme-colors, primary);
      }
    }
  }

  .elementor-widget-tabs .elementor-tab-title {
    color: map-get($map: $theme-colors, $key: primary);

    &.elementor-active {
      background-color: map-get($map: $theme-colors, $key: primary);
      color: #fff;
    }
  }

  .elementor-accordion {
    .elementor-tab-title {
      color: map-get($map: $theme-colors, $key: primary);
    }

    .elementor-accordion-icon-closed,
    .elementor-accordion-icon-opened {
      color: map-get($map: $theme-colors, $key: bodyGrey);
    }
  }


  .cta {
    .elementor-button {
      background-color: #fff;
      border-color: map-get($map: $theme-colors, $key: primary);
      color: map-get($map: $theme-colors, $key: primary);
    }
  }

  .shadow-card {
    border: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }

  .inverse {
    color: #fff;

    a,
    h1,
    h2,
    h3,
    h4,
    .elementor-widget-text-editor,
    .elementor-widget-heading .elementor-heading-title {
      color: #fff;
    }
  }

  .white {
    .elementor-button {
      border: 1px solid transparent;
      padding: 0.75rem 3rem;
    }
  }

  &:not(.elementor-page) .page-header {
    margin-top: 30px;
  }
}
